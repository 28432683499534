import React from 'react'
import InPagePushAd from '../../components/AdFormat/In_Page_Push_Page/InPagePushAd'

const InPagePushAds = () => {
  return (
    <div>
        <InPagePushAd/>
    </div>
  )
}

export default InPagePushAds