import React from 'react'
import { CpaNetwork } from '../../components/CpaNetwork/CpaNetwork'

const CpaNetworks = () => {
  return (
    <>
    <CpaNetwork/>
    </>
  )
}

export default CpaNetworks