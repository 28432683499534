export const AdvertiserBenefitsProsData = [
    {
        name: 'VPN & Utilities',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/bb3901d7-3b74-46f5-dc21-df6b86a3a000/public',
    },
    {
        name: 'Mobile Apps',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/56e7e3ed-2314-4b34-01a1-617f84c48b00/public',
    },
    {
        name: 'Dating',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/8b30caf2-0bb1-43eb-f2bd-52e22ec48b00/public',
    },
    {
        name: 'E-commerce',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/79609aa1-d3ec-476e-f0b8-69ca6d66fe00/public',
    },
    {
        name: 'iGaming',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/5bc63ba9-a7c1-4a31-7603-7d63d96bf700/public',

    },
    {
        name: 'Finance',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/bb3901d7-3b74-46f5-dc21-df6b86a3a000/public',

    },
    {
        name: 'Subscriptions',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/5ead73c8-40df-4103-8624-a16638531800/public',

    },
    {
        name: 'Lead Generation',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/e3023eab-ac49-4256-1603-11683fc46a00/public'
    },
    {
        name: 'Sweepstakes',
        image: 'https://imagedelivery.net/f5tF3V4WaB6L98qcq1rX5w/42e25258-5da9-4b0d-1ea5-e3486db13800/public'
    }

]