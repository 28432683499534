export const sliderData = [
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Deepak Kothari",
      role: "Influencer & Visionary",
      // imageSrc: "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://images.ctfassets.net/wp1lcwdav1p1/5NkS0Uw1feX56zRTS3DNGM/07493c326318d8e0eebb9c93d163876e/GettyImages-1197547359__2_.jpg?w=1500&h=680&q=60&fit=fill&f=faces&fm=jpg&fl=progressive&auto=format%2Ccompress&dpr=1&w=1000&h=",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Satyam Sai",
      role: "Influencer & Visionary",
      // imageSrc: "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://images.ctfassets.net/wp1lcwdav1p1/5NkS0Uw1feX56zRTS3DNGM/07493c326318d8e0eebb9c93d163876e/GettyImages-1197547359__2_.jpg?w=1500&h=680&q=60&fit=fill&f=faces&fm=jpg&fl=progressive&auto=format%2Ccompress&dpr=1&w=1000&h=",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Ajeet Kumar",
      role: "Influencer & Visionary",
      // imageSrc: "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://images.ctfassets.net/wp1lcwdav1p1/5NkS0Uw1feX56zRTS3DNGM/07493c326318d8e0eebb9c93d163876e/GettyImages-1197547359__2_.jpg?w=1500&h=680&q=60&fit=fill&f=faces&fm=jpg&fl=progressive&auto=format%2Ccompress&dpr=1&w=1000&h=",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Deepak Kumar",
      role: "Influencer & Visionary",
      // imageSrc: "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://images.ctfassets.net/wp1lcwdav1p1/5NkS0Uw1feX56zRTS3DNGM/07493c326318d8e0eebb9c93d163876e/GettyImages-1197547359__2_.jpg?w=1500&h=680&q=60&fit=fill&f=faces&fm=jpg&fl=progressive&auto=format%2Ccompress&dpr=1&w=1000&h=",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Rahul Kaushal",
      role: "Influencer & Visionary",
      // imageSrc: "https://d3njjcbhbojbot.cloudfront.net/api/utilities/v1/imageproxy/https://images.ctfassets.net/wp1lcwdav1p1/5NkS0Uw1feX56zRTS3DNGM/07493c326318d8e0eebb9c93d163876e/GettyImages-1197547359__2_.jpg?w=1500&h=680&q=60&fit=fill&f=faces&fm=jpg&fl=progressive&auto=format%2Ccompress&dpr=1&w=1000&h=",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Abhinandan Mittal",
      role: "Influencer & Visionary",
      // imageSrc: "https://GrowXAd.com/_nuxt/img/silva.f39f8cd.png",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Ashok Kumar",
      role: "Influencer & Visionary",
      // imageSrc: "https://GrowXAd.com/_nuxt/img/silva.f39f8cd.png",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Abhishek Bhadhwar",
      role: "Influencer & Visionary",
      // imageSrc: "https://GrowXAd.com/_nuxt/img/silva.f39f8cd.png",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Ravi Kumar",
      role: "Influencer & Visionary",
      // imageSrc: "https://GrowXAd.com/_nuxt/img/silva.f39f8cd.png",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Sadhana Rathor",
      role: "Influencer & Visionary",
      // imageSrc: "https://GrowXAd.com/_nuxt/img/silva.f39f8cd.png",
    },
  },
  {
    content:
      "I've been working with GrowXAd since 2015 and I love their traffic. The platform has constantly been improving, but more importantly, the attention and quality support we receive from their managers have been awesome.",
    // imageSrc: "http://localhost:3000/images/quotation.png",
    author: {
      name: "Sonali Saluja",
      role: "Influencer & Visionary",
      // imageSrc: "https://GrowXAd.com/_nuxt/img/silva.f39f8cd.png",
    },
  },
  // Add more slider items as needed
];
export const settings = {
  centerMode: true,
  infinite: true,
  centerPadding: "40",
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

