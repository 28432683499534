import React from 'react'

import AdvertiserBenefits from '../../components/Advertiser/AdvertiserBenefit'

const AdvertisersBenefitsPage = () => {
  return (
    <div>
      <AdvertiserBenefits />
    </div>
  )
}

export default AdvertisersBenefitsPage;