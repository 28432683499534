import React from 'react'

const SectionSeperator = () => {
  return (
    <div className='h-6 lg:h-8'>

    </div>
  )
}

export default SectionSeperator