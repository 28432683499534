import React from 'react'
import RtbTraffic from '../../components/Advertiser/RtbTraffic/RtbTraffic'

const AdvertisersRtbPage = () => {
  return (
    <div>
      <RtbTraffic/>
        
    </div>
  )
}

export default AdvertisersRtbPage