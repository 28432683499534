import React from 'react'
import BannerAd from '../../components/AdFormat/BannerAd/BannerAd'


const BannerAds = () => {

    return (
        <>
            <BannerAd />

        </>
    )
}

export default BannerAds