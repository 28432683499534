import React from 'react'

const TbSeperator = () => {
  return (
    <div className='mt-3 mb-3'>
        
    </div>
  )
}

export default TbSeperator