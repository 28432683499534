import React from 'react'
import AdvertiserPricing from '../../components/AdvertiserPricing/AdvertiserPricing'

const AdvertisersPricingPage = () => {
  return (
    <div>
      <AdvertiserPricing />
    </div>
  )
}

export default AdvertisersPricingPage