import React from 'react'

import CpaGoals from '../../components/Advertiser/CpaGoals/CpaGoals'

const AdvertisersCpaGoalPage = () => {
  return (
    <div>
      <CpaGoals />


    </div>
  )
}

export default AdvertisersCpaGoalPage