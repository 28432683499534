import React from 'react'
import AdvertiserSelfServe from '../../components/Advertiser/AdvertiserSelfServe'

const AdvertisersSelfServePage = () => {
  return (
    <div>
      <AdvertiserSelfServe />
    </div>
  )
}

export default AdvertisersSelfServePage